export default [
  {
    key: 'password',
    placeholder: 'placeholder.password',
    label: 'field.newPassword',
    rules: 'required|password|min:8',
    type: 'password',
    cols: 12,
    // description: "general.passwordDescription",
    // hideDescription: true,
  },
  {
    key: 'retypeNewPassword',
    label: 'field.retypeNewPassword',
    placeholder: 'placeholder.password',
    rules: 'required|confirmed:password',
    type: 'password',
    cols: 12,
  },
]
